/* Media queries
----------------------------- */

@mixin phone {
  @media (max-width: 768px) {
    @content;
  }
}
@mixin tablet {
  @media (max-width: 1024px) {
    @content;
  }
}
@mixin desktop {
  @media (max-width: 1280px) {
    @content;
  }
}

/* Core styles
----------------------------- */
@import url('https://fonts.googleapis.com/css?family=Ubuntu:300,500&subset=latin-ext');

body,
html {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

body {
  font-size: 10px;
  font-family: 'Roboto', sans-serif; 
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

a {
  color: $color1;
  text-decoration: underline;
  &:hover {
    color: $color2;
    text-decoration: none;
  }
}


h1 {
  font-size: 3.4em;
  font-family: 'Roboto', sans-serif; 
}

h2 {
  font-size: 3em;
}

h3 {
  font-size: 2.6em;
}

h4 {
  font-size: 2.2em;
}

h5 {
  font-size: 1.8em;
}

/* Custom styles
----------------------------- */

textarea:focus, input:focus {
  outline: 0;
}

select:focus, textarea:focus, input:focus {
  outline: 0;
  box-shadow: none;
}


::-moz-placeholder {
  color: #b2b2b2;
  font-size: 1.8em;
  font-family: 'Ubuntu', sans-serif;
}

:-ms-input-placeholder {
  color: #b2b2b2;
  font-size: 1.8em;
  font-family: 'Ubuntu', sans-serif;
}

::-webkit-input-placeholder {
  color: #b2b2b2;
  font-size: 1.8em;
  font-family: 'Ubuntu', sans-serif;
}

input:focus::-webkit-input-placeholder { 
  color: transparent; 
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}