/* Imports
----------------------------- */

// Reset
@import "reset";

// Vendors
@import "vendors/lightbox";
@import "vendors/slick-theme";
@import "vendors/slick";
@import "vendors/jquery-ui.min";
@import "vendors/select2.min";
@import "vendors/animate.min";

// Base and Variables
@import "variables";
@import "base";

// Partials
@import "buttons";
@import "checkboxes";
@import "menu";

// Pages
@import "pages/home";

// Responsive
@import "responsive";