/* Buttons
   ----------------------------- */

.btn-fill {
    background: transparent;
    padding: 9px 30px 15px;
    color: #fff;
    font-size: 22px;
    letter-spacing: 1px;
    line-height: 26px;
    white-space: nowrap;
    text-align: center;
    border: solid 1px $color2;
    border-radius: 10px;
    display: inline-block;
    position: relative;
    z-index: 0;
    overflow: hidden;
    transition: all 0.3s ease;
    &:after {
        content: "";
        width: 150%;
        height: 100%;
        position: absolute;
        top: 0;
        left: -20%; 
        background: $color2;
        z-index: -1;
        transform: skew(-30deg);
        transform-origin: left top;
        transition: all 0.2s cubic-bezier(0,0,0.58,1);
    }
    &:hover {
        color: $color2;
        text-decoration: none;
        &:after {
            left: -150%;
        }
    }
    &:focus {
        color: #fff;
        text-decoration: none;
        &:hover {
            color: #fff;
            text-decoration: none;
        }
    }
    &:active {
        color: #fff;
        text-decoration: none;
    }
}

.btn-border {
    background: transparent;
    padding: 9px 30px 15px;
    color: #fff;
    font-size: 22px;
    letter-spacing: 1px;
    line-height: 26px;
    white-space: nowrap;
    text-align: center;
    border: solid 1px #fff;
    border-radius: 10px;
    display: inline-block;
    position: relative;
    z-index: 0;
    overflow: hidden;
    transition: all 0.3s ease;
    &:after {
        content: "";
        width: 150%;
        height: 100%;
        position: absolute;
        top: 0;
        left: -150%; 
        background: #fff;
        z-index: -1;
        transform: skew(-30deg);
        transform-origin: left top;
        transition: all 0.2s cubic-bezier(0,0,0.58,1);
    }
    &:hover {
        color: $color2;
        text-decoration: none;
        &:after {
            left: -20%;
        }
    }
    &:focus {
        color: #fff;
        text-decoration: none;
        &:hover {
            color: #fff;
            text-decoration: none;
        }
    }
    &:active {
        color: #fff;
        text-decoration: none;
    }
}